import { render, staticRenderFns } from "./Disk.vue?vue&type=template&id=70df2760&scoped=true&"
import script from "./Disk.vue?vue&type=script&lang=ts&"
export * from "./Disk.vue?vue&type=script&lang=ts&"
import style0 from "./Disk.vue?vue&type=style&index=0&id=70df2760&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70df2760",
  null
  
)

export default component.exports