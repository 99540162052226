





















import { Vue, Component, Watch } from 'vue-property-decorator'
import { LogBookUser, LogBookUserState } from 'shared/state/LogBookUser'
import { LogBookUserDefinition } from 'shared/types/logBook'
import { Competitions } from 'shared/state/Competitions'

@Component({ components: {} })
export default class HeaderLinkAccount extends Vue {
  @LogBookUserState('logBookUserInfo') user!: LogBookUserDefinition
  @LogBookUserState() LogBookUserState
  @LogBookUserState() loggedIn!: boolean

  get avatar() {
	return (this.user.profile_image)
	? this.user.profile_image
	: `/assets/img/cs-user.svg`
  }

  get classObject() {
    return !!(this.user.profile_image) ?
    {avatar: true } : {avatar: true, 'no-border-radius': true, unlogged: true}
  }

  logMeOut() {
	Competitions.logout()
  }
}
