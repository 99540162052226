

























import { Component, Prop, Vue } from 'vue-property-decorator'
// import ServerMonitor from 'shared/components/ServerMonitor.vue'
import InstancesTable from './InstancesTable.vue'
import Memory from './Memory.vue'
import Disk from './Disk.vue'
import Instances from './Instances.vue'
import Config from './Config.vue'
import { Server } from 'https'


@Component({ components: { Memory, Disk, Instances, InstancesTable, Config } })
export default class ServerArea extends Vue {
	@Prop() server!: any
	@Prop() serverMonitor!: any
}

