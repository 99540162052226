

















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { bus } from 'shared/state/Bus'
import { getCookie } from 'shared/util/cookie'
import { LogBookUser, LogBookUserState } from 'shared/state/LogBookUser'
import { LogBookUserDefinition, LogBookCredentials, Instance, CompetitionInstance, RaceServer, AdminUser } from 'shared/types/logBook'
import Announcement from './Announcement.vue'

const axios = require('axios').default

@Component({ components: { Announcement } })
export default class Config extends Vue {
    @Prop() server!: any
	@Prop() serverMonitor!: any
	@LogBookUserState() logBookUserInfo!: LogBookUserDefinition

	config: any = {}
	settings: any = {}

	originalConfig: any = {}

	saving = false
	savingSettings = false
	supported = true

	get monitorVersion() {
	  return this.serverMonitor.monitor.monitor.version
  	}

	get isWebServer() {
		return ['rs1.ergrace.com', 'd.ergrace.com', 'localhost'].includes(this.server.name) 
		&& (this.monitorVersion > '1.39.0' || this.server.name == 'localhost')
	}

	get originalSettings() {
		return {...this.serverMonitor.monitor.monitor.settings}
	}

	cancelAPIConfig() {
		this.config = {...this.originalConfig}
	}

	cancelMonitorSettings() {
		this.settings = {...this.originalSettings}
	}

	hyphenize(val:string) {
		return val.replace(/([_, \.])/g, "-")
	}

	spaceize(val: string) {
		return val.replace(/([_, \.])/g, " ")
	}

	calcType(val, o: any) {
		if(typeof(o[val]) === 'boolean') {
			return 'radio'
		}
		return ['string', 'Array', 'object'].includes(typeof(o[val])) ? 'text' : 'number'
	}

	formDirty(which?:string) {
		if(which==='monitor') {
			return JSON.stringify(this.typeCast(this.settings)) !== JSON.stringify(this.monitorReceivedObject)
		}
		return JSON.stringify(this.typeCast(this.config)) !== JSON.stringify(this.originalConfig)
	}


	typeCast(o?:any) {
		let a = {...this.settings}, original = {...this.monitorReceivedObject}
		if(o===this.config) {
			a = {...this.config}
			original = {...this.originalConfig}
		}
		Object.keys(a).forEach(p=> {
			if(typeof(original[p]) === 'number') {
				a[p] = parseInt(a[p])
			}
			if(typeof(original[p]) === 'object') {
				a[p] = a[p].replace(/\s/g, "").split(',')
			}
			
		})

		return a
	}

	async saveToAPI() {
		this.saving = true
		let token = getCookie('logbooktoken')
	
	  	if(this.logBookUserInfo?.username && token) {

			  const postData = {...this.typeCast(this.config)}

				try {
				const response = await fetch(this.server.url + `/instance/config`, {
					method: "post",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${getCookie('logbooktoken')}`
					},
					body: JSON.stringify(postData)
				})

				if (!response.ok) {
					const message = 'Error with Status Code: ' + response.status;
					throw new Error(message);
				}

				const data = await response.json()
				this.originalConfig = { ...data?.settings }
			    this.config = data?.settings
				
				
				} catch (error) {
					console.error('Error: ' + error)
				}
				this.saving = false
		}
	}

	saveSettings() {
		this.savingSettings = true
		this.serverMonitor.monitor.monitor.server.send({ action: "settings", detail: this.convertSettingsBack})
	}

	get monitorReceivedObject() {
		const s = { ...this.serverMonitor.monitor.monitor.settings }
		if(!s) return null
		Object.keys(s).forEach(p=>{
			if(typeof s[p] === 'object') {
				s[p] = s[p].toString()
			}
		})
		return s
	}
	get convertSettingsBack() {
		let a = {}
		let s = this.serverMonitor.monitor.monitor.settings
		
		Object.keys(s).forEach(p=>{
			switch (typeof s[p]) {
				case 'number':
					a[p] = parseInt(this.settings[p])
					break
				case 'object':
					a[p] = this.settings[p].replace(/\s/g, "").split(',')
					break
				default: 
				    a[p] = this.settings[p]

			}
		})
		return a
	}

  @Watch('logBookUserInfo', { immediate: true, deep: true })
  async logBookInfoChanged(newVal) {
	  let token = getCookie('logbooktoken')
	
	  if(this.logBookUserInfo?.username && token) {
		  this.saving = true
			let result = await axios
			.post(this.server.url + `/instance/config`, {}, {
				headers: {
					Authorization: `Bearer ${getCookie('logbooktoken')}`,
					'content-type': 'text/json'
				}
			}).catch(err=>{
				this.supported = false
			})
			this.saving = false
		
		if(result?.data && token) {
			this.originalConfig = { ...result.data?.settings }
			this.config = result.data?.settings
		}
		this.saving = false
		this.settings = {...this.monitorReceivedObject}
	  }
	  
  }

  handleBusSettings(settings: any) {
		this.savingSettings = false
		this.settings = {...this.monitorReceivedObject}
  }

  created() {
	  bus.$on('got_settings', this.handleBusSettings)
  }

  beforeDestroy() {
	  bus.$off('got_settings', this.handleBusSettings)
  }
  

}


