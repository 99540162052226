const planetChartDataC = {
	type: 'line',
	data: {
		labels: ['Mercury', 'Venus', 'Earth', 'Mars', 'Jupiter', 'Saturn', 'Uranus', 'Neptune'],
		datasets: [
			{
				// one line graph
				label: 'Number of Moons',
				data: [0, 0, 1, 2, 67, 62, 27, 14],
				backgroundColor: [
					'rgba(54,73,93,.5)', // Blue
					'rgba(54,73,93,.5)',
					'rgba(54,73,93,.5)',
					'rgba(54,73,93,.5)',
					'rgba(54,73,93,.5)',
					'rgba(54,73,93,.5)',
					'rgba(54,73,93,.5)',
					'rgba(54,73,93,.5)'
				],
				borderColor: ['#36495d', '#36495d', '#36495d', '#36495d', '#36495d', '#36495d', '#36495d', '#36495d'],
				borderWidth: 3
			},
			{
				// another line graph
				label: 'Planet Mass (x1,000 km)',
				data: [4.8, 12.1, 12.7, 6.7, 139.8, 116.4, 50.7, 49.2],
				backgroundColor: [
					'rgba(71, 183,132,.5)' // Green
				],
				borderColor: ['#47b784'],
				borderWidth: 3
			}
		]
	},
	options: {
		responsive: true,
		lineTension: 1,
		scales: {
			yAxes: [
				{
					ticks: {
						beginAtZero: true,
						padding: 25
					}
				}
			]
		}
	}
}

const doughnutChartDataC = {
	type: 'doughnut',
	data: {
		// These labels appear in the legend and in the tooltips when hovering different arcs
		labels: ['Red', 'Green', 'Blue'],
		datasets: [
			{
				data: [10, 20, 30],
				backgroundColor: [
					'#ff000080',
					'rgba(71, 183,132,.5)', // Green
					'#0000ff80' // Blue
				],
				borderColor: ['red', '#47b784', '#0000ff']
			}
		]
	}
}

const serverDataC = {
	servers: [
		{
			id: 1,
			server: 1,
			name: 'rs1',
			url: 'https://rs1.ergrace.com'
		}
	],
	instanceCount: [
		{
			id: 1,
			server: 1,
			datetime: '2020-12-31T12:5:01',
			count: 10
		},
		{
			id: 2,
			server: 1,
			datetime: '2020-12-31T12:10:01',
			count: 12
		},
		{
			id: 3,
			server: 1,
			datetime: '2020-12-31T12:15:01',
			count: 12
		},
		{
			id: 4,
			server: 1,
			datetime: '2020-12-31T12:20:01',
			count: 8
		}
	],
	instances: [
		{
			id: 1,
			server: 1,
			datetime: '2020-12-31T12:5:01',
			pid: 25,
			port: 50001,
			state: 'inactive'
		},
		{
			id: 2,
			server: 1,
			datetime: '2020-12-31T12:5:05',
			port: 50001,
			pid: 25,
			state: 'inactive'
		},
		{
			id: 3,
			server: 1,
			datetime: '2020-12-31T12:5:10',
			port: 50001,
			pid: 25,
			state: 'inactive'
		},
		{
			id: 4,
			server: 1,
			datetime: '2020-12-31T12:5:15',
			port: 50001,
			pid: 25,
			state: 'warmup'
		},
		{
			id: 5,
			server: 1,
			datetime: '2020-12-31T12:5:01',
			pid: 27,
			port: 50020,
			state: 'inactive'
		},
		{
			id: 6,
			server: 1,
			datetime: '2020-12-31T12:5:05',
			pid: 27,
			port: 50020,
			state: 'inactive'
		},
		{
			id: 7,
			server: 1,
			datetime: '2020-12-31T12:5:10',
			pid: 27,
			port: 50020,
			state: 'inactive'
		},
		{
			id: 8,
			server: 1,
			datetime: '2020-12-31T12:5:15',
			pid: 27,
			port: 50020,
			state: 'warmup'
		}
	]
}

export const planetChartData = planetChartDataC
export const doughnutChartData = doughnutChartDataC
export const serverData = serverDataC
