












import { Component, Prop, Vue } from 'vue-property-decorator'
import Chart from 'chart.js'
import { planetChartData, doughnutChartData } from 'shared/util/chart-data.js'

@Component({ components: { } })
export default class Instances extends Vue {

createChart(chartId, chartData) {
    const ctx = document.getElementById(chartId)
    const myChart = new Chart(ctx, {
      type: chartData.type,
      data: chartData.data,
      options: chartData.options,
    })
  }
  mounted() {
	  this.createChart('planet-chart-2', planetChartData)
	  
  }

}

