















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import Chart from 'chart.js'
import { planetChartData, doughnutChartData } from 'shared/util/chart-data.js'
import { setCookie, getCookie } from 'shared/util/cookie'
import { bus } from 'shared/state/Bus'
import ServerMonitor from 'shared/components/ServerMonitor.vue'
import { RemoteControlClass } from 'shared/state/RemoteControl'
import { ServerModule}  from 'shared/state/Server'
import Message from './Message.vue'

const axios = require('axios').default


@Component({ components: { Message } })
export default class InstancesTable extends Vue {
@Prop() server!: any
@Prop() serverMonitor!: any

state = ''
restarting = {}
competition = ''

fields = [
	{key: 'action', label: '', sortable: false, class: 'text-center'}, 
	{key: 'organizer', label: 'Organizer', sortable: true, class: 'text-center'},
	{key: 'pretty_code', label: 'competition', sortable: true, class: 'text-center'},
    {key: 'created_at', label: 'created', sortable: true, class: 'text-center'}, 
	{key: 'race_status', label: 'status', sortable: true, class: 'text-center'}, 
	{key: 'hp_clients', label: 'HP Clients', sortable: true, class: 'text-center'},
	{key: 'erg_clients', label: 'ER Clients (M/D)', sortable: true, class: 'text-center'},
	{key: 'hp_port', label: 'HP Port', sortable: true, class: 'text-center'}, 
	{key: 'erg_port', label: 'ER Port', sortable: true, class: 'text-center'}, 
	{key: 'hp_ids', label: 'HP IDs (PM/Win)', sortable: true, class: 'text-center'},
	{key: 'erg_ids', label: 'ER IDs (PM/Win)', sortable: true, class: 'text-center'},
	{key: 'hp_mem', label: 'HP Mem', sortable: true, class: 'text-center'},
	{key: 'erg_mem', label: 'Erg Mem', sortable: true, class: 'text-center'},
	{key: 'versions', label: 'Versions (HP/Erg)', sortable: true, class: 'text-center'},


]
items:any[] = []
updated = new Date()
timer 
competitions:any[] = []
instances: any[] = []
filterVal = ''
output = ''
restartTitle = ''
checkingHPport = 0
checkingErgport = 0
selected:string = ''
ApiVersion = '0.0'

get store() {
	return this.serverMonitor.monitor.monitor.store
}
get users() {
	return  this.serverMonitor.monitor.monitor.users
}

get options() {
	return this.competitions.filter(c=>c.organizer !== 'disconnected').map(c=>{return {value:c.code, text : c.pretty_code}})
}
showSb() {
		bus.$emit('showSB', this.serverMonitor.server.name)
}
isAutomated(code) {
	var c = code.toString().replace(/[-]/g, '')
	const autos = this.serverMonitor.monitor.monitor.settings.AUTOMATED_COMPETITIONS
	return autos ? autos.includes(c) : false
	// legacy ['5591052823', '4270364121'].includes(code)
}

  hyphenize(word:string) {
	  return word.replace('.', '-')
  }

  strip(word: string) {
	  return word.replace(/[-]/g, '')
  }

  liveViewLink(value: string) {
	  return `https://live.ergrace.com/race/${this.strip(value)}/#/scroll`
  }
  get monitorVersion() {
	  return this.serverMonitor.monitor.monitor.version
  }

  async created() {
	  this.update()
	  this.timer = setInterval(async ()=>{
		  this.update()
		  // the following line can be removed once all servers upgraded > v1.28.0
		  if(this.monitorVersion === '<1.31.0') {
              this.serverMonitor.monitor.monitor.send({action: "get_store"})
		  }
		  if(this.monitorVersion >= '1.33.0') {
			  this.serverMonitor.monitor.monitor.send({action: "get_users"})
		  }
   }, 5000)

  }
  beforeDestroy() {
	  clearTimeout(this.timer)
  }

  raceStatus(port: number) {
	let erg = this.serverMonitor.ergs.find(e => e.erg_port == port)
	return erg ? erg.ergrace.erg.status : '?'
  }

  erg(port: number) {
	  return this.serverMonitor.ergs.find(e => e.erg_port == port)
  }
  hp(port: number) {
	  return this.serverMonitor.hps.find(e => e.hp_port == port)
  }

  connections(port: number) {
	let erg = this.erg(port)
	return erg ? erg.ergrace.remote_control.RemoteErg.connections : {displayClients: '0', mobileClients: '0'}
  }

  HPMobileClients(port: number) {
	let hp = this.hp(port)
	return hp ? hp.hp.remote_control.RemoteErg.connections.mobileClients : '0'
  }

  async update() {
	  const temp  = await this.getData()
	  this.items = []
	  this.items = temp
	  this.updated = new Date()
	  const tds = document.getElementsByTagName('td')
	  for (let element of tds) {
		  element.className = 'align-middle'
	  }

	  bus.$emit('got_competition_instances', this.server, this.items)
  }
 

  message(item: any) {
	  if(!item?.code) return
	  this.competition = item.code
	  this.selected = item.code
	  this.showSb()
  }

  shutDown(item: any) {
	const athletes = item.hp_clients + item.mobile_clients
	const p = athletes > 1
	this.$bvModal
	.msgBoxConfirm(
		`Are you sure you want to shut down Holding Pen and Ergrace for Competition ${item.pretty_code}?` + 
			(athletes ? ` There ${p?'are':'is'} ${athletes} athlete${p?'s':''} attached. `: ``),
		{
			size: 'sm',
			buttonSize: 'lg',
			okVariant: 'success',
			cancelVariant: 'danger',
			okTitle: 'Yes',
			cancelTitle: 'No',
			bodyClass: 'bdy',
			footerClass: 'p-2',
			hideHeaderClose: false,
			centered: true,
		}
	)
	.then(async (response) => {
		if (response && item.code) {
			
			let result = await axios
			.get(this.server.url + `/instance/delete/${item.code}`, {
				headers: {
					Authorization: `Bearer ${getCookie('logbooktoken')}`
				}
			})
			if(!result?.data?.success) {
				this.$bvModal.msgBoxOk('Shut down failed. ' + result.data.data)
				return
			}
			const m = 'Competition was shut down by Concept2. If you would like to resume, refresh your screen.'
			const action = { 
			"action": "notify",
				"detail": {
					"competition": item.code,
					"message": m,
				}
			}
			this.serverMonitor.monitor.monitor.send(action)
			//this.messages.push({sent: m, competition: item.code })
			
		}
	})
  }
  
  restart(item: any) {
	const athletes = item.hp_clients + item.mobile_clients
	const p = athletes > 1
	this.$bvModal
	.msgBoxConfirm(
		`Are you sure you want to restart Holding Pen and Ergrace for Competition ${item.pretty_code}?` + 
			(athletes ? ` There ${p?'are':'is'} ${athletes} athlete${p?'s':''} attached. `: ``),
		{
			size: 'sm',
			buttonSize: 'lg',
			okVariant: 'success',
			cancelVariant: 'danger',
			okTitle: 'Yes',
			cancelTitle: 'No',
			bodyClass: 'bdy',
			footerClass: 'p-2',
			hideHeaderClose: false,
			centered: true,
		}
	)
	.then(async (response) => {
		if (response && item.code) {
			const m = 'Competition was restarted by Concept2.'
			const action = { 
			"action": "notify",
				"detail": {
					"competition": item.code,
					"message": m,
				}
			}
			this.serverMonitor.monitor.monitor.send(action)
			//this.messages.push({sent: m, competition: item.code })
			this.restartTitle = 'Restarting ' + item.pretty_code
			this.checkingHPport = item.hp_port
			this.checkingErgport = item.erg_port
			this.output = ''
			this.$bvModal.show('modal-output-' + this.hyphenize(this.server.name))
			this.restartHP()
		}
	})
  }
  
  safeParseInt(val) {
	  try {
		  return parseInt(val)
	  }
	  catch {
		  return 0
	  }
  }

  get HPconnected() {
	if(this.checkingHPport === 0) return 'unknown'
	return this.hp(this.checkingHPport).hp.server.state 
  }

  get ErgConnected() {
	if(this.checkingErgport === 0) return 'unknown'
	return this.erg(this.checkingErgport).ergrace.server.state
  }

  restartHP() {
	this.state = 'HP restarting'
	const hp = this.hp(this.checkingHPport)
	const hpRc:RemoteControlClass = hp.hp.remote_control
	const hpServer:ServerModule = hp.hp.server
	hpRc.remoteErgAction('exit')
  }
  
  restartErg() {
	this.state = 'Erg restarting'
	const erg = this.erg(this.checkingErgport)
	const ergRc:RemoteControlClass = erg.ergrace.remote_control
	const ergServer:ServerModule = erg.ergrace.server
	ergRc.remoteErgAction('exit')
  }

  async getData() {
	       axios
	       .get(this.server.url + `/instance/version`)
			.then((resp) => { 
				this.ApiVersion = resp.data.version
			})
	        return await axios
			.get(this.server.url + `/instance`, {
				headers: {
					Authorization: `Bearer ${getCookie('logbooktoken')}`
				}
			})
			.then((response) => {
				const instanceData = response.data.data
				this.competitions = []
				this.instances = instanceData
				let promises: Promise<any>[] = []
				if (instanceData && response.data.success) {
					instanceData.forEach(i => {
						const isHP = i.name.includes('pen')
						const o:any = {
							code: i.code, 
							pretty_code: `${i.code.slice(0,3)}-${i.code.slice(3,6)}-${i.code.slice(6,15)}`
						}
						if(isHP) { 
							o.hp_port = i.port
							o.hp_ids = i.id + ' / ' + i.pid
							o.hp_mem = i.mem
							o.hp_clients = this.safeParseInt(this.HPMobileClients(i.port))
							const s = this.hp(i.port)?.hp?.remote_control?.RemoteErg?.HPVersion
							o.hp_version = s ? s : ''
						}
						else {
							o.erg_clients = this.connections(i.port).mobileClients + ' / ' + this.connections(i.port).displayClients
							o.race_status = this.raceStatus(i.port)
							o.mobile_clients = this.safeParseInt(this.connections(i.port).mobileClients)
							o.display_clients = this.safeParseInt(this.connections(i.port).displayClients)
							o.erg_port = i.port
							o.erg_pid = i.pid
							o.erg_id = i.id
							o.erg_ids = i.id + ' / ' + i.pid
							o.erg_mem = i.mem
							const s = this.erg(i.port)?.ergrace?.remote_control?.RemoteErg?.ErgVersion
							o.erg_version = s ? s : ''
							o.created_at = new Date(i.created_at).toLocaleString()
							
						}
						let foundIndex = this.competitions.findIndex(c=>c.code === i.code)
						if(foundIndex == -1) { this.competitions.push(o)}
						else {
							this.competitions[foundIndex] = {...this.competitions[foundIndex], ...o}
						}
					})
				}
                this.competitions.forEach(c=>{
					c.versions = c.hp_version + ' ' + c.erg_version
					const p = this.store.find(s=>s.competition == c.code)
					const q = this.users.find(s=>s.competition == c.code)
					c.organizer = 
						p? p.first_name + ' ' + p.last_name : 
						q? q.first_name + ' ' + q.last_name :
						'disconnected'
					c.is_connected = !!p
				})
				return this.competitions
			})
			.catch((err) => {
				return
			})
  }
  prettify(code:string) {
	  return `${code.slice(0,3)}-${code.slice(3,6)}-${code.slice(6,15)}`
  }

  markup(val, state) {
	  const out = state.replace('disconnected', 'shut down')
	  return `<div class="${val}">${out}</div>`
	   
  }
  @Watch('HPconnected', {immediate: true}) 
  HPconnectedChanged(newVal) {
	if(newVal === 'disconnected') {
		setTimeout(()=>{
			this.restartErg()
		}, 6000)
	}
	this.state = 'HP ' + newVal
	if(!['unknown', 'unconnected'].includes(newVal)) {this.output += this.markup(newVal, this.state)}
  }
  @Watch('ErgConnected', {immediate: true}) 
  ErgConnectedChanged(newVal) {
	this.state = 'Erg ' + newVal
	if(!['unknown', 'unconnected'].includes(newVal)) {this.output += this.markup(newVal, this.state)}
  }
  @Watch('state', {immediate: true}) 
  stateChanged(newVal) {
	if(newVal === 'Erg disconnected') {
		setTimeout(()=>{
			this.hp(this.checkingHPport).hp.server.connect()
			this.erg(this.checkingErgport).ergrace.server.connect()
		}, 6000)	
	}
  }
}

