






import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
import CKEditor from '@ckeditor/ckeditor5-vue2'
import CustomEditor from 'ckeditor5-34.2.0-8qnmqmgf65' 
//'ckeditor5-34.2.0-8g68wp25l7yq'


Vue.use( CKEditor )

@Component({ components: { CustomEditor } })
export default class Announcement extends Vue {
	@Prop() value!: string
	cb = false
	editor = CustomEditor
	editorData = ''
	editorConfig = {
		// toolbar: [ 'list', 'alignment', 'bold', 'italic', 'heading', 'fontFamily', 'fontSize', 'fontBackgroundColor', 'fontColor', 'highlight', 'htmlSupport', 'indentBlock', 'link',  ],
	}
	created() {
		if(typeof(this.value)=='string') this.editorData = this.value
	}
	@Watch('value', { immediate: true })
	valueChanged (e) {
		this.editorData = this.value
		this.cb = !!this.value
    }
	@Watch('editorData', { immediate: true })
	editorDataChanged(e){
		if(typeof(e)=='string') this.$emit('input', this.editorData)
    }
	
}
