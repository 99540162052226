











import { Component, Prop, Vue } from 'vue-property-decorator'
import { bus } from 'shared/state/Bus'
import { diskStatusType } from 'shared/types'
import { formatBytes, addData, removeData, createChart } from 'shared/util/charts'

@Component({ components: {  } })
export default class Disk extends Vue {
    @Prop() server!: any

	data = [0,0]
	labels = ['']
	Chart:any = 0

	diskStat:any = 0

	chartName = 'disk-chart-' + ( ~~(Math.random() * 1000) ).toString()

	created() {
		bus.$on('disk_status', this.diskStatus)
	}

	beforeDestroy() {
		bus.$off('disk_status', this.diskStatus)
	}

	mounted() {
		this.Chart = createChart(this.chartName, this.chartData)
	}

	diskStatus(disk_status:diskStatusType, socket: string) { 
		if(socket==this.server.wss && this.Chart) {
			this.diskStat = disk_status
			this.data = [ disk_status.free, (disk_status.total - disk_status.free)]
			
			this.Chart.data.datasets.forEach((dataset) => {
					dataset.data = this.data
			})
			this.Chart.update()

		}

	}

	get total() {
		return this.diskStat ? formatBytes(this.diskStat.total) : ''
	}

	get used() {
		return this.diskStat ? formatBytes(this.diskStat.total - this.diskStat.free) : ''
	}

	get free() {
		return this.diskStat ? formatBytes(this.diskStat.free) : ''
	}

	get filesystem() {
		return this.diskStat ? this.diskStat.filesystem : ''
	}

	refresh() {
		this.data = []
		for (let i = 0; i < 10; i ++ ) {
			this.data.push(Math.random()*10)
		}
		createChart(this.chartName, this.chartData)
	}

	get chartData() {
		let d = {
			type: 'doughnut',
			data: {
				labels: ['Free', 'Used'],
				datasets: [
					{
						data: this.data,
						backgroundColor: [
							'rgba(71, 183,132,.5)', // Green
							'#0000ff80' // Blue
						],
						borderColor: ['#47b784', '#0000ff']
					}
				]
			}
		}
		
		return d
	}
  

}

