















import { Component, Prop, Vue } from 'vue-property-decorator'
import LoginForm from './LoginForm.vue'

@Component({ components: { LoginForm } })
export default class LoginPageContent extends Vue {}
